<template>
  <main>
    <article>
      <section class="ui-step-by-step-box">
        <div class="container">
          <div class="row">
            <div class="columns c-12">
              <h2
                class="has-text-align-center"
                v-html="$t('explanation.headline')"
              ></h2>
            </div>
          </div>
          <div class="row">
            <StepItem
              :headline="$t('explanation.stepHeadline1')"
              :subheadline="$t('explanation.stepSubheadline1')"
              :description="$t('explanation.stepDescription1')"
            ></StepItem>
            <StepItem
              :headline="$t('explanation.stepHeadline2')"
              :subheadline="$t('explanation.stepSubheadline2')"
              :description="$t('explanation.stepDescription2')"
            ></StepItem>
            <StepItem
              :headline="$t('explanation.stepHeadline3')"
              :subheadline="$t('explanation.stepSubheadline3')"
              :description="$t('explanation.stepDescription3')"
            ></StepItem>
            <StepItem
              :headline="$t('explanation.stepHeadline4')"
              :subheadline="$t('explanation.stepSubheadline4')"
              :description="$t('explanation.stepDescription4')"
            ></StepItem>
          </div>
          <div class="row">
            <div class="columns c-12 m-6 m-auto">
              <div class="ui-calendar-item">
                <div class="img-item">
                  <div class="ui-round-icon-box">
                    <!-- <img src="/assets/image/icons/icon-calendar.svg" /> -->
                    <!-- <object
                      data="/assets/image/icons/icon-calendar.svg"
                    ></object> -->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="389.3"
                      height="389.3"
                      viewBox="0 0 389.3 389.3"
                    >
                      <g
                        id="noun_Calendar_441276"
                        transform="translate(-18.47 17.596)"
                      >
                        <path
                          id="Pfad_11"
                          data-name="Pfad 11"
                          d="M78.256,42.267H49.417A14.419,14.419,0,0,0,35,56.684V71.1A14.419,14.419,0,0,0,49.417,85.518H78.256A14.416,14.416,0,0,0,92.673,71.1V56.684A14.416,14.416,0,0,0,78.256,42.267Z"
                          transform="translate(113.236 112.947)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_12"
                          data-name="Pfad 12"
                          d="M94.923,42.267H66.084A14.419,14.419,0,0,0,51.667,56.684V71.1A14.416,14.416,0,0,0,66.084,85.518H94.923A14.416,14.416,0,0,0,109.34,71.1V56.684A14.416,14.416,0,0,0,94.923,42.267Z"
                          transform="translate(168.664 112.947)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_13"
                          data-name="Pfad 13"
                          d="M111.589,42.267H82.754A14.422,14.422,0,0,0,68.333,56.684V71.1A14.419,14.419,0,0,0,82.754,85.518h28.834A14.419,14.419,0,0,0,126.01,71.1V56.684A14.419,14.419,0,0,0,111.589,42.267Z"
                          transform="translate(224.087 112.947)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_14"
                          data-name="Pfad 14"
                          d="M61.589,56.667H32.75A14.416,14.416,0,0,0,18.333,71.084V85.5A14.419,14.419,0,0,0,32.75,99.923H61.589A14.419,14.419,0,0,0,76.006,85.5V71.084A14.413,14.413,0,0,0,61.589,56.667Z"
                          transform="translate(57.809 157.754)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_15"
                          data-name="Pfad 15"
                          d="M78.256,56.667H49.417A14.419,14.419,0,0,0,35,71.084V85.5A14.422,14.422,0,0,0,49.417,99.923H78.256A14.419,14.419,0,0,0,92.673,85.5V71.084A14.416,14.416,0,0,0,78.256,56.667Z"
                          transform="translate(113.236 157.754)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_16"
                          data-name="Pfad 16"
                          d="M94.923,56.667H66.084A14.416,14.416,0,0,0,51.667,71.084V85.5A14.419,14.419,0,0,0,66.084,99.923H94.923A14.419,14.419,0,0,0,109.34,85.5V71.084A14.416,14.416,0,0,0,94.923,56.667Z"
                          transform="translate(168.664 157.754)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_17"
                          data-name="Pfad 17"
                          d="M111.589,56.667H82.754A14.419,14.419,0,0,0,68.333,71.084V85.5A14.423,14.423,0,0,0,82.754,99.923h28.834A14.423,14.423,0,0,0,126.01,85.5V71.084A14.419,14.419,0,0,0,111.589,56.667Z"
                          transform="translate(224.087 157.754)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_18"
                          data-name="Pfad 18"
                          d="M61.589,71.066H32.75A14.419,14.419,0,0,0,18.333,85.487V99.9A14.416,14.416,0,0,0,32.75,114.322H61.589A14.416,14.416,0,0,0,76.006,99.9V85.487A14.419,14.419,0,0,0,61.589,71.066Z"
                          transform="translate(57.809 199.706)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_19"
                          data-name="Pfad 19"
                          d="M78.256,71.066H49.417A14.425,14.425,0,0,0,35,85.487V99.9a14.419,14.419,0,0,0,14.417,14.417H78.256A14.416,14.416,0,0,0,92.673,99.9V85.487A14.422,14.422,0,0,0,78.256,71.066Z"
                          transform="translate(113.236 199.706)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_20"
                          data-name="Pfad 20"
                          d="M94.923,71.066H66.084A14.419,14.419,0,0,0,51.667,85.487V99.9a14.416,14.416,0,0,0,14.417,14.417H94.923A14.416,14.416,0,0,0,109.34,99.9V85.487A14.422,14.422,0,0,0,94.923,71.066Z"
                          transform="translate(168.664 199.706)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_21"
                          data-name="Pfad 21"
                          d="M351.044,15.6H333.02V36.085c0,16.941-14.551,30.728-32.442,30.728s-32.442-13.787-32.442-30.728V15.6H232.092V36.085c0,16.941-14.551,30.728-32.442,30.728s-32.442-13.787-32.442-30.728V15.6H131.163V36.085c0,16.941-14.551,30.728-32.442,30.728S66.28,53.027,66.28,36.085V15.6H48.256C24.4,15.6,5,33.979,5,56.571V297.478c0,22.591,19.4,40.971,43.256,40.971H351.044c23.851,0,43.256-18.379,43.256-40.971V56.571C394.3,33.979,374.9,15.6,351.044,15.6Zm14.417,281.878c0,7.53-6.467,13.656-14.417,13.656H48.256c-7.95,0-14.417-6.125-14.417-13.656V111.2c0-7.543,6.458-13.656,14.417-13.656H351.044c7.963,0,14.417,6.113,14.417,13.656Z"
                          transform="translate(13.47 33.256)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_22"
                          data-name="Pfad 22"
                          d="M43.295,115.307c11.947,0,21.628-12.282,21.628-27.427V33.027C64.923,17.876,55.242,5.6,43.295,5.6S21.667,17.876,21.667,33.027V87.88C21.667,103.025,31.348,115.307,43.295,115.307Z"
                          transform="translate(68.897 -23.196)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_23"
                          data-name="Pfad 23"
                          d="M66.628,115.307c11.947,0,21.628-12.282,21.628-27.427V33.027C88.256,17.876,78.575,5.6,66.628,5.6S45,17.876,45,33.027V87.88C45,103.025,54.685,115.307,66.628,115.307Z"
                          transform="translate(146.492 -23.196)"
                          fill="#011e41"
                        />
                        <path
                          id="Pfad_24"
                          data-name="Pfad 24"
                          d="M89.961,115.307c11.947,0,21.628-12.282,21.628-27.427V33.027c0-15.151-9.681-27.427-21.628-27.427S68.333,17.876,68.333,33.027V87.88C68.333,103.025,78.018,115.307,89.961,115.307Z"
                          transform="translate(224.087 -23.196)"
                          fill="#011e41"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div class="text-item">
                  <h3 class="ui-title" v-html="$t('duration.headline')"></h3>
                  <p class="ui-subtitle">
                    <strong v-html="$t('duration.subHeadline')"></strong>
                  </p>
                  <p
                    class="ui-description"
                    v-html="$t('duration.description')"
                  ></p>
                  <span class="terms-link-container">
                    <i></i>
                    <a
                      v-bind:href="$t('duration.link')"
                      target="_blank"
                      rel="noopener noreferrer"
                      v-html="$t('duration.linkText')"
                    ></a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="columns c-12 only-btn-section has-text-align-center">
              <a
                :href="$store.state.product.reviewUrl"
                class="ui-btn"
                target="_blank"
                rel="noopener noreferrer"
                v-html="$t('explanation.stepBtn')"
              ></a>
            </div>
          </div>
        </div>
      </section>
      <div id="competition-formular" class="ui-form-container padding-box">
        <div class="container">
          <div class="row has-text-align-center">
            <div class="columns c-12">
              <h2 v-html="$t('formular.headline')"></h2>
            </div>
          </div>
          <div class="row">
            <div class="columns c-12 m-8 m-auto">
              <CompetitionFormular />
            </div>
          </div>
          <div class="row has-text-align-center support-box">
            <div class="columns c-12">
              <h3 v-html="$t('helpbox.headline')"></h3>
              <a
                v-bind:href="$t('helpbox.contactUsMail')"
                class="ui-btn"
                target="_blank"
                rel="noopener noreferrer"
                v-html="$t('helpbox.btn')"
              ></a>
            </div>
          </div>
        </div>
      </div>
    </article>
  </main>
  <hr />
</template>

<script>
// import PriceBoxItem from './PriceBoxItem.vue'
import StepItem from "./StepItem.vue";
import CompetitionFormular from "./CompetitionFormular.vue";

export default {
  name: "MainArea",
  components: {
    StepItem,
    // PriceBoxItem,
    CompetitionFormular,
  },
};
</script>

<style></style>
