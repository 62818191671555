<template>
  <form
    class="ui-form"
    @submit.prevent="submit"
    id="giftForm"
    v-if="!submitted"
  >
    <div class="ui-form-inner">
      <div class="row">
        <div
          class="ui-form-item columns c-12"
          :class="{ hasError: giftChoiceError, hasSuccess: !giftChoiceError }"
          v-if="giftChoiceAvailable"
        >
          <label for="giftChoice">
            <span v-html="$t('formular.formGiftChoice')"></span>
            <sup>*</sup>
          </label>
          <Multiselect
            v-model="multiselect"
            v-bind="giftChoice"
            @select="setGiftChoiceValue(selectedOption)"
            @input="changeGiftChoiceValue()"
          >
            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="character-label-icon" :src="value.icon" />
                {{ value.name }}
              </div>
            </template>
            <template v-slot:option="{ option }">
              <img class="character-option-icon" :src="option.icon" />
              {{ option.name }}
            </template>
          </Multiselect>

          <input type="hidden" name="giftChoiceValue" />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.giftChoiceValue.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12"
          :class="{
            hasError: v$.form.name.$errors.length > 0,
            hasSuccess: !v$.form.name.$invalid,
          }"
        >
          <label for="name">
            <span v-html="$t('formular.formName')"></span>
            <sup>*</sup>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            :placeholder="$t('formular.formName')"
            v-model="v$.form.name.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.name.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12"
          :class="{
            hasError: v$.form.address.$errors.length > 0,
            hasSuccess: !v$.form.address.$invalid,
          }"
        >
          <label for="address">
            <span v-html="$t('formular.formAddress')"></span>
            <sup>*</sup>
          </label>
          <input
            type="text"
            name="address"
            id="address"
            :placeholder="$t('formular.formAddress')"
            v-model="v$.form.address.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.address.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12 m-4"
          :class="{
            hasError: v$.form.zip.$errors.length > 0,
            hasSuccess: !v$.form.zip.$invalid,
          }"
        >
          <label for="zip">
            <span v-html="$t('formular.formZIP')"></span>
            <sup>*</sup>
          </label>
          <input
            type="text"
            name="zip"
            id="zip"
            :placeholder="$t('formular.formZIP')"
            v-model="v$.form.zip.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.zip.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12 m-8"
          :class="{
            hasError: v$.form.city.$errors.length > 0,
            hasSuccess: !v$.form.city.$invalid,
          }"
        >
          <label for="city">
            <span v-html="$t('formular.formCity')"></span>
            <sup>*</sup>
          </label>
          <input
            type="text"
            name="city"
            id="city"
            :placeholder="$t('formular.formCity')"
            v-model="v$.form.city.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.city.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12"
          :class="{
            hasError: v$.form.country.$errors.length > 0,
            hasSuccess: !v$.form.country.$invalid,
          }"
        >
          <label for="country">
            <span v-html="$t('formular.formCountry')"></span>
            <sup>*</sup>
          </label>
          <input
            type="text"
            name="country"
            id="country"
            :placeholder="$t('formular.formCountry')"
            v-model="v$.form.country.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.country.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12"
          :class="{
            hasError: v$.form.phone.$errors.length > 0,
            hasSuccess: !v$.form.phone.$invalid,
          }"
        >
          <label for="phone">
            <span v-html="$t('formular.formPhone')"></span>
            <sup>*</sup>
          </label>
          <input
            type="number"
            name="phone"
            id="phone"
            :placeholder="$t('formular.formPhone')"
            v-model="v$.form.phone.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.phone.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12"
          :class="{
            hasError: v$.form.email.$errors.length > 0,
            hasSuccess: !v$.form.email.$invalid,
          }"
        >
          <label for="email">
            <span v-html="$t('formular.formEmail')"></span>
            <sup>*</sup>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            :placeholder="$t('formular.formEmail')"
            v-model="v$.form.email.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.email.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12"
          :class="{
            hasError: v$.form.nickname.$errors.length > 0,
            hasSuccess: !v$.form.nickname.$invalid,
          }"
        >
          <label for="nickname">
            <span v-html="$t('formular.formNickname')"></span>
            <sup>*</sup>
          </label>
          <input
            type="text"
            name="nickname"
            id="nickname"
            :placeholder="$t('formular.formNickname')"
            v-model="v$.form.nickname.$model"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.nickname.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12"
          :class="{
            hasError: v$.form.receipt.$errors.length > 0,
            hasSuccess: !v$.form.receipt.$invalid,
          }"
          v-if="receiptUploadActive"
        >
          <label for="receipt">
            <span v-html="$t('formular.formReceipt')"></span>
            <sup>*</sup>
          </label>
          <input
            type="file"
            name="receipt"
            id="receipt"
            accept=".jpg,.jpeg,.png,.pdf"
          />
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.receipt.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div
          class="ui-form-item columns c-12"
          :class="{
            hasError: v$.form.checkTerms.$errors.length > 0,
            hasSuccess: !v$.form.checkTerms.$invalid,
          }"
        >
          <div class="check-item">
            <input
              type="checkbox"
              name="checkTerms"
              id="checkTerms"
              v-model="v$.form.checkTerms.$model"
            />
            <span class="checkbox-checked-container" v-if="termsChecked">
              <i></i>
            </span>
            <label for="checkTerms" @click="handleTermsChecked()">
              <span v-html="$t('formular.formCheckTerms')"></span>
              <sup>*</sup>
            </label>
          </div>
          <div
            class="input-errors"
            v-for="(error, index) of v$.form.checkTerms.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
          <span class="terms-link-container">
            <i></i>
            <a
              :href="$t('formular.formPrivacyPolicyLink')"
              v-html="$t('formular.formPrivacyPolicy')"
            ></a>
          </span>
        </div>

        <div class="ui-form-item submit columns c-12">
          <button
            type="submit"
            name="enter-results"
            id="enter-results"
            class="ui-btn"
            v-html="$t('formular.formSend')"
          ></button>
        </div>
      </div>
    </div>
  </form>
  <div class="formSubmitted" v-if="submitted">
    <div class="row">
      <div class="columns c-12">
        <div class="ui-form-success-message">
          <img src="/assets/image/icons/icon-success.svg" alt="" />
          <h3 v-html="$t('successMessageBox.headline')"></h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "../utils/i18n-validators";

export default {
  name: "NewForm",
  setup() {
    return { v$: useVuelidate() };
  },
  updated() {
    // this.giftChoiceAvailable = this.$store.state.giftAvailable;
    let product = this.$store.state.product;
    if (product.gifts && product.gifts.length > 0) {
      // this.giftOptiopns= new Array();
      let options = new Array()
      console.log(this.currentLang)
      console.log(this.$i18n.locale)
      this.giftOptiopns.splice(0, this.giftOptiopns.length, ...options);
      // if(this.currentLang != this.$i18n.locale){
      //   this.giftOptiopns.splice(0, this.giftOptiopns.length, ...options);
      //   this.giftChoice.options.length = 0
      //   console.log("regenerate");
      //   this.currentLang = this.$i18n.locale
      // }
      
      for (let index = 0; index < product.gifts.length; index++) {
        const element = product.gifts[index];

        let newArray = {
          value: index + 1,
          name: element.name,
          icon: element.imgUrl,
        };
        let index = this.giftOptiopns.findIndex((x) => x.icon == element.imgUrl);
        index === -1
          ? this.giftOptiopns.push(newArray)
          : console.log("object already exists");
      }
      this.giftChoiceAvailable = true;
      console.log(this.giftOptiopns)
      this.giftChoice.options.splice(0, this.giftChoice.options.length, ...this.giftOptiopns);
      // this.giftChoice.options = this.giftOptiopns;
      console.log(this.giftChoice.options)
      this.giftChoice.placeholder = this.$t("formular.formGiftChoice");
      this.giftChoice.label = this.$t("formular.formGiftChoice");
      this.receiptUploadActive = this.$store.state.receiptUploadActive;
    } else {
      this.giftChoiceAvailable = false;
    }
  },
  data() {
    return {
      multiselect: null,
      form: {
        giftChoice: "",
        giftChoiceValue: "",
        name: "",
        address: "",
        zip: "",
        city: "",
        country: "",
        phone: "",
        email: "",
        nickname: "",
        receipt: "",
        checkTerms: "",
      },
      termsChecked: false,
      submitted: false,
      fileName: "",
      giftChoiceAvailable: false,
      giftOptiopns: [],
      giftChoice: {
        value: null,
        placeholder: this.$t("formular.formGiftChoice"),
        label: this.$t("formular.formGiftChoice"),
        options: [],
        // options: [
        //   {
        //     value: this.$t("formular.formGiftChoiceOption1"),
        //     name: this.$t("formular.formGiftChoiceOption1"),
        //     icon: this.$t("formular.formGiftChoiceOptionImage1"),
        //   },
        //   {
        //     value: this.$t("formular.formGiftChoiceOption2"),
        //     name: this.$t("formular.formGiftChoiceOption2"),
        //     icon: this.$t("formular.formGiftChoiceOptionImage2"),
        //   },
        // ],
        // options: ,
      },
      giftChoiceError: false,
      receiptUploadActive: false,
      productId: "",
      currentLang:""
    };
  },
  validations() {
    return {
      form: {
        giftChoice: {
          // required
        },
        giftChoiceValue: {
          // required
        },
        name: {
          required,
        },
        address: {
          required,
        },
        zip: {
          required,
        },
        city: {
          required,
        },
        country: {
          required,
        },
        phone: {
          required,
        },
        email: {
          required,
          email,
        },
        nickname: {
          required,
        },
        receipt: {},
        checkTerms: {
          required,
        },
      },
    };
  },
  methods: {
    handleTermsChecked() {
      this.termsChecked = !this.termsChecked;
    },
    productIsChoosen() {
      this.giftChoiceError = true;
      if (document.getElementsByName("giftChoiceValue").length != 0) {
        if (
          document.getElementsByName("giftChoiceValue")[0].value ||
          document.getElementsByName("giftChoiceValue")[0].value != ""
        )
          this.giftChoiceError = false;
      } 
      else {
        this.giftChoiceError = false;
      }
    },
    setGiftChoiceValue() {
      document.getElementsByName("giftChoiceValue")[0].value = this.multiselect;
      this.productIsChoosen();
      // this.$refs.multiselect.open()
      // this.v$.form.giftChoiceValue.setValue(this.multiselect);
    },
    changeGiftChoiceValue() {
      document.getElementsByName("giftChoiceValue")[0].value = "";
      console.log("change");
    },
    async submit() {
      // console.log(this.form)
      
      this.v$.form.$touch();
      this.productIsChoosen();
      console.log(this.v$.form.$pending);
      console.log(this.v$.form.$error);
      console.log(this.giftChoiceError);
      if (
        this.v$.form.$pending ||
        this.v$.form.$error ||
        this.giftChoiceError
      ) {
        console.log(this.v$.form.$error);
        console.log('return');
        return;
      }
      let giftForm = document.getElementById("giftForm");
      let formData = new FormData(giftForm);
      formData.append("language", this.$i18n.locale);
      formData.append("productId", this.$store.state.product.id);
      formData.append(
        "campaign",
        this.$store.state.settings.campaign === undefined
          ? 0
          : this.$store.state.settings.campaign
      );

      fetch(this.apiUrl + "api/customer", {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.status == 400) {
            console.log("validation error");
            return;
          }
          if (response.status == 409) {
            console.log("E-Mail already exists");
            return;
          }
          if (response.status == 200) {
            console.log("User created");
            this.submitted = true;
            return;
          }
          console.log();
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>
<style></style>
