<template>
  <Navigation />
  <HeroHeader />
  <MainArea />
  <!-- <TermsAndConditions /> -->
  <Footer />
</template>

<script>
import Navigation from "../components/Navigation.vue";
import HeroHeader from "../components/HeroHeader.vue";
import MainArea from "../components/MainArea.vue";
// import TermsAndConditions from '../components/TermsAndConditions.vue'
import Footer from "../components/Footer.vue";

import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";
import i18n from "../i18n";
// import NewForm from '../components/NewForm.vue'

export default {
  name: "Home",
  props: {
    product: { required: true },
    campaign: { required: true },
  },
  components: {
    HeroHeader,
    Navigation,
    MainArea,
    // TermsAndConditions,
    Footer,
    // NewForm
  },
  created() {
    console.log("created");//ELX_FI_Ultimate_800_green_varsi_imuri
    //http://localhost:8086 | https://incentive.electroluxreviews.com
    fetch(
      "https://incentive.electroluxreviews.com/api/campaign/" + this.campaign,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((r) => r.json())
      .then((response) => {
        console.log(response);
        this.$store.commit("setSettings", response);
        this.setLocalValues2();
        // Store.settings = response;
        // console.log(Store.settings);
      })
      .catch((e) => console.log(e));
    this.generateStyleContainer("fontCss");
    this.generateStyleContainer("variablesFromServer");
    this.generateStyleContainer("overwriteCss");

    const siteData = reactive({
      robots: "index, follow",
      title: "Reviews",
      description: "",
      ogType: "website",
      ogSiteName: "Reviews",
      publishedTime: "2023-02-15T17:00:00+00:00",
    });

    useHead({
      title: computed(() => siteData.title),
      link: [
        {
          rel: "apple-touch-icon",
          href: computed(() => siteData.appleTouchIcon),
        },
      ],
      meta: [
        {
          name: "robots",
          content: computed(() => siteData.robots),
        },
        {
          name: "description",
          content: computed(() => siteData.description),
        },
        {
          name: "og:type",
          content: computed(() => siteData.ogType),
        },
        {
          name: "og:title",
          content: computed(() => siteData.title),
        },
        {
          name: "og:description",
          content: computed(() => siteData.description),
        },
        {
          name: "og:url",
          content: computed(() => siteData.ogUrl),
        },
        {
          name: "og:site_name",
          content: computed(() => siteData.ogSiteName),
        },
        {
          name: "article:published_time",
          content: computed(() => siteData.publishedTime),
        },
      ],
    });
  },
  mounted() {
    // this.setLocalValues2();

    console.log("mounted");
  },
  updated() {
    this.setLocalValues2();
  },
  methods: {
    generateStyleContainer(element) {
      let newElement = document.createElement("style");
      if (document.querySelector(`#${element}`))
        document.querySelector(`#${element}`).remove();
      newElement.id = element;
      newElement.setAttribute("type", "text/css");
      document.head.append(newElement);
    },
    setLocalValues2() {
      const markets = this.$store.state.settings.markets;
      let constantVariables = "";
      let languages = new Array();
      this.$store.commit(
        "setreceiptUploadActive",
        this.$store.state.settings.receiptUploadActive
      );
      for (let index = 0; index < markets.length; index++) {
        const element = markets[index];
        console.log(element.lang);
        if (element.lang.toLowerCase() == this.$i18n.locale) {
          console.log(this.product);

          i18n.global.setLocaleMessage(element.lang, element.translations);
          i18n.global.locale = element.lang;
          i18n.locale = element.lang;
          /* Set Product Values */
          for (let j = 0; j < element.products.length; j++) {
            const subElement = element.products[j];
            console.log(subElement.name);
            if (subElement.name.toLowerCase() == this.product.toLowerCase()) {
              console.log(subElement);

              this.$store.commit("setProduct", subElement);

              constantVariables += `--headImg:url('${subElement.imgUrl}');`;

              break;
            }
          }

          /*Set Brand Values */
          let brand = element.brand;
          this.$store.commit("setLogo", brand.logo);
          constantVariables += `--prime:${brand.colors.prime};
                --primeTransparent:${brand.colors.prime}E5;
                --brown-1:${brand.colors.color2};
                --white:${brand.colors.color3};
                --black:${brand.colors.color4};
                --blackTransparent:${brand.colors.color4}60;
                --blackTransparent09:${brand.colors.color4}E5;
                --gray-1:${brand.colors.color5};
                --gray-2:${brand.colors.color5};
                --gray-3:${brand.colors.color5};`;
          if (document.querySelector("#variablesFromServer")) {
            document.querySelector(
              "#variablesFromServer"
            ).textContent = `body{${constantVariables}}`;
          }
          if (document.querySelector("#overwriteCss")) {
            document.querySelector("#overwriteCss").textContent =
              brand.cssOVerwrite;
          }

          if (document.querySelector("#fontCss")) {
            let fonts = "";
            brand.fonts.forEach((element) => {
              fonts += `@font-face {
                        font-family: '${element.name}';
                        src: url("${element.url}");`;
            });
            document.querySelector("#fontCss").textContent = fonts;
          }

          /* End Brand Values */

          this.$store.commit("setGiftAvailable", element.giftAvailable);
        } else {
          console.log("false");
        }
        languages.push(element.lang);
        if (markets.length == index + 1) {
          console.log(languages);
          this.$store.commit("setLanguages", languages);
        }
      }
    },
    setLocalValues() {
      const products = this.$store.state.settings.products;
      console.log(this.$store.state.settings);
      for (let index = 0; index < products.length; index++) {
        const element = products[index];
        if (element.name.toLowerCase() == this.product.toLowerCase()) {
          // this.$store.commit("setProduct",element.id)
          // console.log(element)
          let languages = new Array();
          for (let index = 0; index < element.brandsites.length; index++) {
            const brandsite = element.brandsites[index];
            languages.push(brandsite.lang);

            if (brandsite.lang === this.$i18n.locale) {
              if (document.querySelector("#overwriteCss")) {
                document.querySelector("#overwriteCss").textContent =
                  brandsite.cssOVerwrite;
              }
              this.$store.commit("setCssOverwrite", brandsite.cssOVerwrite);
              this.$store.commit("setBrandSite", brandsite.url);
              this.$store.commit("setProduct", brandsite.id);
              this.$store.commit("setGiftAvailable", element.giftAvailable);
              this.$store.commit("setCampaign", this.campaign);
            }
            console.log("Element " + element.brandsites.length);
            console.log("Index" + index);
            if (element.brandsites.length == index + 1) {
              console.log(languages);
              this.$store.commit("setLanguages", languages);
            }
          }
        }
      }
      console.log(this.$store.state);
    },
  },
};
</script>

<style>
/* {{brandsite.cssOverwrite}} */

/* body {
  background: red;
} */
</style>
