<template>
  <div class="columns c-12 m-3">
    <div class="ui-step-item">
      <h3 class="ui-title" v-html="headline"></h3>
      <p class="ui-subtitle"><strong v-html="subheadline"></strong></p>
      <p class="ui-description" v-html="description"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepItem',
  props: {
    headline: String,
    subheadline:String,
    description:String
  }
}
</script>
<style>

</style>